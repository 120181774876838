import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RecurringDays from '../forms/components/RecurringDays';
import MultipleDates from '../forms/components/MultipleDates';

const token = Cookies.get('token');

const VisitorQRCodesPage = () => {
    const { visitorId } = useParams();
    const [qrCodes, setQrCodes] = useState([]);
    const [expiredQRCodes, setExpiredQRCodes] = useState([]);
    const [editingQRCode, setEditingQRCode] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [lifetimeValidity, setLifetimeValidity] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState('');
    const [qrToModify, setQrToModify] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    // const [formData, setFormData] = useState({
    //     recurringDays: [],
    //     multipleDays: [],
    // });
    const [hasRecurringDaysErrors, setHasRecurringDaysErrors] = useState(false);
    const handleRecurringDaysValidation = (isInvalid) => {
        setHasRecurringDaysErrors(isInvalid);
        if (isInvalid) {
            setErrorMessage('Please correct the recurring days errors.');
        }
        console.log('Recurring Days Validation:', isInvalid);
        handleEndDateChange(endDate);
    };
    // eslint-disable-next-line
    const [hasMultipleDatesErrors, setHasMultipleDatesErrors] = useState(false);
    const handleMultipleDatesValidation = useCallback((isInvalid) => {
        setHasMultipleDatesErrors(isInvalid);
        if (isInvalid) {
            setErrorMessage('Please correct the multiple dates errors.');
        } else {
            setErrorMessage('');
        }
        console.log('Multiple Dates Validation:', isInvalid);
    }, []);

    const editSectionRef = useRef(null);  // To handle scroll behavior

    useEffect(() => {
        const fetchQRCodes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/visitor/${visitorId}/qr-codes`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                // Separate active and expired QR codes
                const activeQRCodes = response.data.filter(qr => !qr.endDate || new Date(qr.endDate) > new Date());
                const expiredQRCodes = response.data.filter(qr => qr.endDate && new Date(qr.endDate) <= new Date());

                setQrCodes(activeQRCodes);
                setExpiredQRCodes(expiredQRCodes);
            } catch (error) {
                console.error('Error fetching QR codes:', error);
            }
        };

        fetchQRCodes();
    }, [visitorId]);

    const formatDate = (date) => {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    };

    const formatDate2 = (date) => {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    };

    const handleEditClick = (qrCode) => {
        setErrorMessage(''); // Clear any previous error message
        let localStartDate = new Date(qrCode.startDate);
        let localEndDate = new Date(qrCode.endDate);

        setEditingQRCode(qrCode);
        setStartDate(localStartDate);
        setEndDate(localEndDate);
        setLifetimeValidity(qrCode.lifetimeValidity || false);

        // Scroll down to the edit section
        setTimeout(() => {
            editSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 200); // Add a small delay to ensure the edit section becomes visible
    };

    const setDefaultDates = () => {
        const today = new Date();
        const tomorrow = new Date(today);

        // Set to midnight (12:00 AM)
        today.setHours(0, 0, 0, 0);
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(today.getDate() + 1);

        setStartDate(today);
        setEndDate(tomorrow);
    };

    const handleSaveClick = async () => {
        // Validate end date is after start date
        if (endDate && new Date(endDate) <= new Date(startDate)) {
            setErrorMessage('End date and time must be later than start date and time.');
            return; // Stop further execution
        }

        // If validation passes, reset error message
        setErrorMessage('');

        try {
            let startDateUTC = new Date(startDate).toISOString();
            let endDateUTC;

            if (lifetimeValidity) {
                endDateUTC = new Date(startDateUTC).setFullYear(new Date().getFullYear() + 100);
            } else {
                endDateUTC = new Date(endDate).toISOString();
            }

            // Sort `recurringDays` by day order
            const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            let recurringDays = (editingQRCode.recurringDays || []).sort(
                (a, b) => daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day)
            );

            // Sort `multipleDays` by date
            let multipleDays = (editingQRCode.multipleDays || []).sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            );

            // Conditionally update start and end dates if qrType is "multipleDays"
            if (editingQRCode.qrType === 'randomDates' && multipleDays.length > 0) {
                startDateUTC = `${multipleDays[0].date}T${multipleDays[0].startTime}`;
                endDateUTC = `${multipleDays[multipleDays.length - 1].date}T${multipleDays[0].endTime}`;
            }

            // Save the updated QR code data
            await axios.put(`${process.env.REACT_APP_API_URL}/api/visitor/${editingQRCode._id}/qr-codes`,
                { startDate: startDateUTC, endDate: endDateUTC, lifetimeValidity, recurringDays, multipleDays },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            // Refetch the QR codes to get the latest values after the update
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/visitor/${visitorId}/qr-codes`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            // Separate active and expired QR codes
            const activeQRCodes = response.data.filter(qr => !qr.endDate || new Date(qr.endDate) > new Date());
            const expiredQRCodes = response.data.filter(qr => qr.endDate && new Date(qr.endDate) <= new Date());

            setQrCodes(activeQRCodes);
            setExpiredQRCodes(expiredQRCodes);

            setEditingQRCode(null); // Clear the editing state
            setStartDate(null);
            setEndDate(null);
            setLifetimeValidity(false);
            setSuccessMessage('QR code updated successfully!');

            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            console.error('Error updating QR code:', error);
        }
    };

    const formatTimeTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleTimeString('en-US', options); // 'en-US' locale uses 12-hour format with AM/PM
    };

    const handleChange = useCallback((event) => {
        const { name, value } = event.target;
        setEditingQRCode((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleLifetimeValidityChange = (checked, qrType) => {
        setLifetimeValidity(checked);

        if (checked && qrType !== 'randomDates') {
            if (hasRecurringDaysErrors) {
                setErrorMessage('Please correct the recurring days errors.');
                return;
            }
            else {
                setErrorMessage('');
            }
            // Set the end date to 100 years later when lifetime validity is checked
            const startDateUTC = startDate || new Date();
            const endDateUTC = new Date(startDateUTC);
            endDateUTC.setFullYear(endDateUTC.getFullYear() + 100);
            setEndDate(endDateUTC);
        } else {
            // Set default dates when lifetime validity is unchecked
            setDefaultDates();
            setErrorMessage('');
        }
    };

    const handleExpireQRCode = (qrCodeId) => {
        setActionType('expire');
        setQrToModify(qrCodeId);
        setShowModal(true);
    };

    const handleDeleteQRCode = (qrCodeId) => {
        setActionType('delete');
        setQrToModify(qrCodeId);
        setShowModal(true);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        // Clear any previous error
        setErrorMessage('');

        if (editingQRCode.qrType !== 'randomDates') {
            if (hasRecurringDaysErrors) {
                setErrorMessage('Please correct the recurring days errors.');
                return;
            }
            else {
                setErrorMessage('');
            }
        }

        if (endDate && new Date(date) >= new Date(endDate)) {
            setErrorMessage('End date cannot be before start date.');
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        // Clear any previous error
        setErrorMessage('');

        if (editingQRCode.qrType !== 'randomDates') {
            if (hasRecurringDaysErrors) {
                setErrorMessage('Please correct the recurring days errors.');
                return;
            }
            else {
                setErrorMessage('');
            }
        }

        if (startDate && new Date(date) <= new Date(startDate)) {
            setErrorMessage('End date cannot be before start date.');
        }
    };

    const handleConfirmAction = async () => {
        if (actionType === 'expire') {
            try {
                // Expire the QR code
                await axios.put(`${process.env.REACT_APP_API_URL}/api/visitor/qr-codes/${qrToModify}/expire`, {}, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                // Update the state by removing it from active and adding to expired
                const updatedActiveQRCodes = qrCodes.filter(qr => qr._id !== qrToModify);
                const expiredQRCode = qrCodes.find(qr => qr._id === qrToModify);
                expiredQRCode.endDate = new Date();  // Set the expiration date
                const updatedExpiredQRCodes = [...expiredQRCodes, expiredQRCode];

                setQrCodes(updatedActiveQRCodes);
                setExpiredQRCodes(updatedExpiredQRCodes);
                setSuccessMessage('QR code expired successfully!');
                setTimeout(() => setSuccessMessage(''), 3000);

            } catch (error) {
                console.error('Error expiring QR code:', error);
            }
        } else if (actionType === 'delete') {
            try {
                // Delete the QR code
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/visitor/qr-codes/${qrToModify}`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                // Update the expired QR codes state
                setExpiredQRCodes(expiredQRCodes.filter(qr => qr._id !== qrToModify));
                setSuccessMessage('QR code deleted successfully!');
                setTimeout(() => setSuccessMessage(''), 3000);

            } catch (error) {
                console.error('Error deleting QR code:', error);
            }
        }

        // Close the modal after the action
        setShowModal(false);
    };


    return (
        <div className="p-4 bg-white border rounded shadow-lg">
            {showModal && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-md">
                        <h3 className="text-lg font-semibold mb-4">
                            {actionType === 'expire' ? 'Expire QR Code' : 'Delete QR Code'}
                        </h3>
                        <p className="mb-4">
                            Are you sure you want to {actionType === 'expire' ? 'expire' : 'delete'} this QR code?
                        </p>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-4"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={handleConfirmAction}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <h2 className="text-xl font-bold mb-4">QR Codes for Visitor</h2>

            {successMessage && (
                <div className="p-2 mb-4 bg-green-200 text-green-800 border border-green-400 rounded">
                    {successMessage}
                </div>
            )}

            {/* Active QR Codes Table */}
            <h3 className="text-lg font-semibold mb-2">Active QR Codes</h3>
            <table className="w-full border mb-6">
                <thead className="border-b bg-gray-200 text-sm font-medium">
                    <tr>
                        <th className="border px-4 py-2 text-left align-top">First Name</th>
                        <th className="border px-4 py-2 text-left align-top">Last Name</th>
                        <th className="border px-4 py-2 text-left align-top">QR Type</th>
                        <th className="border px-4 py-2 text-left align-top">Start Date</th>
                        <th className="border px-4 py-2 text-left align-top">End Date</th>
                        <th className="border px-4 py-2 text-left align-top">Location</th>
                        <th className="border px-4 py-2 text-left align-top">Created At</th>
                        <th className="border px-4 py-2 text-left align-top">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {qrCodes.map(qr => (
                        <tr key={qr._id}>
                            <td className="border px-4 py-2 align-top text-left">{qr.qrFirstName}</td>
                            <td className="border px-4 py-2 align-top text-left">{qr.qrLastName}</td>

                            {/* QR Type Column */}
                            <td className="border px-6 py-3 align-top text-left">
                                {qr.qrType === 'betweenDates' ? (
                                    <span className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold px-3 py-1 rounded-full">
                                        Between Dates
                                    </span>
                                ) : qr.qrType === 'weekdays' ? (
                                    <div>
                                        <span className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-3 py-1 rounded-full">
                                            Recurring Weekdays
                                        </span>
                                        <ul className="ml-4 mt-2 list-disc text-sm">
                                            {qr.recurringDays.map((day, index) => (
                                                <li key={index}>
                                                    <span className="font-medium">{day.day}</span>: {formatTimeTo12Hour(day.startTime)} - {formatTimeTo12Hour(day.endTime)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : qr.qrType === 'randomDates' ? (
                                    <div>
                                        <span className="inline-block bg-purple-100 text-purple-800 text-xs font-semibold px-3 py-1 rounded-full">
                                            Multiple Dates
                                        </span>
                                        <ul className="ml-4 mt-2 list-disc text-sm">
                                            {qr.multipleDays.map((day, index) => (
                                                <li key={index}>
                                                    <span className="font-medium">{formatDate2(day.date)}</span>: {formatTimeTo12Hour(day.startTime)} - {formatTimeTo12Hour(day.endTime)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <span className="inline-block bg-gray-100 text-gray-800 text-xs font-semibold px-3 py-1 rounded-full">
                                        Unknown Type
                                    </span>
                                )}
                            </td>

                            {/* Start Date Column */}
                            <td className="border px-4 py-2 align-top text-left">
                                {(qr.qrType === 'betweenDates' || qr.qrType === 'weekdays' || qr.qrType === 'randomDates') ? formatDate(qr.startDate) : 'N/A'}
                            </td>

                            {/* End Date Column */}
                            <td className="border px-4 py-2 align-top text-left">
                                {qr.lifetimeValidity
                                    ? 'Permanent Visitor'
                                    : (qr.qrType === 'betweenDates' || qr.qrType === 'weekdays' || qr.qrType === 'randomDates')
                                        ? formatDate(qr.endDate)
                                        : 'N/A'}
                            </td>

                            <td className="border px-4 py-2 align-top text-left">{qr.propertyName}</td>
                            <td className="border px-4 py-2 align-top text-left">{formatDate(qr.createdAt)}</td>

                            {/* Actions Column */}
                            <td className="border px-4 py-2 align-top text-left">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleEditClick(JSON.parse(JSON.stringify(qr)))}
                                >
                                    Edit
                                </button>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                                    onClick={() => handleExpireQRCode(qr._id)}
                                >
                                    Expire
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Expired QR Codes Table */}
            <h3 className="text-lg font-semibold mb-2">Expired QR Codes</h3>
            <table className="w-full border mb-6">
                <thead className="border-b bg-gray-200 text-sm font-medium">
                    <tr>
                        <th className="border px-4 py-2 text-left align-top">First Name</th>
                        <th className="border px-4 py-2 text-left align-top">Last Name</th>
                        <th className="border px-4 py-2 text-left align-top">QR Type</th>
                        <th className="border px-4 py-2 text-left align-top">Start Date</th>
                        <th className="border px-4 py-2 text-left align-top">End Date</th>
                        <th className="border px-4 py-2 text-left align-top">Location</th>
                        <th className="border px-4 py-2 text-left align-top">Created At</th>
                        <th className="border px-4 py-2 text-left align-top">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {expiredQRCodes.map(qr => (
                        <tr key={qr._id}>
                            <td className="border px-4 py-2 align-top text-left">{qr.qrFirstName}</td>
                            <td className="border px-4 py-2 align-top text-left">{qr.qrLastName}</td>

                            {/* QR Type Column */}
                            <td className="border px-6 py-3 align-top text-left">
                                {qr.qrType === 'betweenDates' ? (
                                    <span className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold px-3 py-1 rounded-full">
                                        Between Dates
                                    </span>
                                ) : qr.qrType === 'weekdays' ? (
                                    <div>
                                        <span className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-3 py-1 rounded-full">
                                            Recurring Weekdays
                                        </span>
                                        <ul className="ml-4 mt-2 list-disc text-sm">
                                            {qr.recurringDays.map((day, index) => (
                                                <li key={index}>
                                                    <span className="font-medium">{day.day}</span>: {formatTimeTo12Hour(day.startTime)} - {formatTimeTo12Hour(day.endTime)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : qr.qrType === 'randomDates' ? (
                                    <div>
                                        <span className="inline-block bg-purple-100 text-purple-800 text-xs font-semibold px-3 py-1 rounded-full">
                                            Multiple Dates
                                        </span>
                                        <ul className="ml-4 mt-2 list-disc text-sm">
                                            {qr.multipleDays.map((day, index) => (
                                                <li key={index}>
                                                    <span className="font-medium">{formatDate2(day.date)}</span>: {formatTimeTo12Hour(day.startTime)} - {formatTimeTo12Hour(day.endTime)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <span className="inline-block bg-gray-100 text-gray-800 text-xs font-semibold px-3 py-1 rounded-full">
                                        Unknown Type
                                    </span>
                                )}
                            </td>

                            {/* Start Date Column */}
                            <td className="border px-4 py-2 align-top text-left">
                                {(qr.qrType === 'betweenDates' || qr.qrType === 'weekdays' || qr.qrType === 'randomDates') ? formatDate(qr.startDate) : 'N/A'}
                            </td>

                            {/* End Date Column */}
                            <td className="border px-4 py-2 align-top text-left">
                                {(qr.qrType === 'betweenDates' || qr.qrType === 'weekdays' || qr.qrType === 'randomDates') ? formatDate(qr.endDate) : 'N/A'}
                            </td>

                            <td className="border px-4 py-2 align-top text-left">{qr.propertyName}</td>
                            <td className="border px-4 py-2 align-top text-left">{formatDate(qr.createdAt)}</td>

                            {/* Actions Column */}
                            <td className="border px-4 py-2 align-top text-left">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleEditClick(JSON.parse(JSON.stringify(qr)))}
                                >
                                    Edit
                                </button>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                                    onClick={() => handleDeleteQRCode(qr._id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Edit QR Code Form */}
            {editingQRCode && (
                <div ref={editSectionRef} className="mt-6 p-4 bg-gray-100 rounded shadow-md">
                    <h3 className="text-lg font-semibold mb-4">Edit QR Code</h3>
                    {editingQRCode.qrType !== 'randomDates' && (
                        <>
                            <div className="mb-4">
                                <label className="block mb-2">Start Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}  // Updated handler
                                    showTimeSelect
                                    dateFormat="Pp"
                                    className="w-full px-3 py-2 border rounded"
                                    timeIntervals={60}
                                />
                            </div>

                            <div className="mb-4">

                                {!lifetimeValidity && (
                                    <>
                                        <label className="block mb-2">End Date</label>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="w-full px-3 py-2 border rounded"
                                            timeIntervals={60}
                                        />
                                    </>
                                )}
                            </div>

                            <div className="mb-4">
                                <label className="inline-flex items-center cursor-pointer space-x-2">
                                    <input
                                        type="checkbox"
                                        checked={lifetimeValidity}
                                        onChange={e => handleLifetimeValidityChange(e.target.checked)}
                                        className="cursor-pointer"
                                    />
                                    <span>Lifetime Validity</span>
                                </label>
                            </div>
                        </>
                    )}
                    {editingQRCode.qrType === 'weekdays' && (
                        <RecurringDays formData={editingQRCode} handleChange={handleChange} onValidationChange={handleRecurringDaysValidation} />
                    )}
                    {editingQRCode.qrType === 'randomDates' && (
                        <MultipleDates formData={editingQRCode} handleChange={handleChange} onValidationChange={handleMultipleDatesValidation} />
                    )}

                    {/* Error Message Display */}
                    {errorMessage && (
                        <div className="p-2 mb-4 bg-red-200 text-red-800 border border-red-400 rounded w-full sm:w-1/2 lg:w-1/3 xl:w-1/3">
                            {errorMessage}
                        </div>
                    )}

                    {/* Save and Cancel Buttons */}

                    <div className="flex">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded mr-4"
                            onClick={handleSaveClick}
                            disabled={!!errorMessage}
                        >
                            Save Changes
                        </button>
                        <button
                            className="bg-gray-500 text-white px-4 py-2 rounded"
                            onClick={() => setEditingQRCode(null)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisitorQRCodesPage;
