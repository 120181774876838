// src/components/RegisterProperty.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../modals/Modal';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie'; // Import js-cookie

const RegisterProperty = () => {
    const [propertyName, setPropertyName] = useState('');
    const [propertyLocation, setPropertyLocation] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [userRole, setUserRole] = useState('');
    const [redirect, setRedirect] = useState(false);

    const token = Cookies.get('token'); // Retrieve token from cookies

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                // setUserRole(decodedToken.role);
                if (decodedToken.role !== 'superadmin') {
                    setRedirect(true); // Set redirect flag if not superadmin
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                setError('Invalid token');
                setRedirect(true); // Redirect on token error
            }
        } else {
            setRedirect(true); // Redirect if no token
        }
    }, [token]);

    const handleRegisterProperty = async (e) => {
        e.preventDefault();

        if (!propertyName || !propertyLocation) {
            setError('All fields are required');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/property/register`, {
                propertyName,
                propertyLocation,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.statusCode === 200) {
                console.log("Registered property successfully");
            }

            setSuccess('Property registered successfully');
            setPropertyName('');
            setPropertyLocation('');
            setIsModalOpen(true);
        } catch (err) {
            setError(err.response?.data?.message || 'Registration failed');
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setError('');
        setSuccess('');
    };

    if (redirect) {
        return <Navigate to="/" replace />;
    }

    return (
        <div className="mx-auto max-w-lg">
            <div className="bg-slate-100 border p-8 rounded-lg shadow-lg w-full">
                <h2 className="text-2xl font-bold text-center mb-6">Register Property</h2>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <form onSubmit={handleRegisterProperty}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="propertyName">
                            Property Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="propertyName"
                            type="text"
                            placeholder="Enter property name"
                            value={propertyName}
                            onChange={(e) => setPropertyName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="propertyLocation">
                            Property Location
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="propertyLocation"
                            type="text"
                            placeholder="Enter property location"
                            value={propertyLocation}
                            onChange={(e) => setPropertyLocation(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Register Property
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title="Property Registration Status"
                message={success}
            >
                <p>{success}</p>
            </Modal>
        </div>
    );
};

export default RegisterProperty;
