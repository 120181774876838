import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropertyModal from '../modals/PropertyModal';
import VisitorModal from '../modals/VisitorModal';
import UserModal from '../modals/UserModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import DeviceList from './DevicesList';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Ensure the correct import
import Cookies from 'js-cookie';
import debounce from 'lodash/debounce';

// Remove this from the top-level scope
// const token = Cookies.get('token');

const debouncedFetchData = debounce(async (dataType, search, propertyFilter, roleFilter, setData) => {
  const token = Cookies.get('token');  // Moved inside the function
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/${dataType}/get-all`, {
      params: {
        search,
        property: propertyFilter,
        role: roleFilter,
      },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    setData(response.data);
    // console.log('Data fetched:', response.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}, 500);

const Dashboard = () => {
  const [dataType, setDataType] = useState('resident');
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [propertyFilter, setPropertyFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [properties, setProperties] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');  // Retrieve token each time
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log('Decoded Token:', decodedToken);
        const role = decodedToken.role || null;
        setUserRole(role);
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserRole(null);
      }
    } else {
      console.log('No token found');
      setUserRole(null);
    }
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      const token = Cookies.get('token');  // Retrieve token before API call
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/get-all`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    debouncedFetchData(dataType, search, propertyFilter, roleFilter, setData);
  }, [dataType, search, propertyFilter, roleFilter]);

  useEffect(() => {
    if (roleFilter === 'superadmin') {
      setPropertyFilter('');
    }
  }, [roleFilter]);

  const handleEdit = (item) => {
    setSelectedItem(item);
    if (dataType === 'resident') {
      setIsModalOpen(true);
    } else if (dataType === 'property') {
      setIsPropertyModalOpen(true);
    } else if (dataType === 'visitor') {
      setIsVisitorModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsPropertyModalOpen(false);
    setIsVisitorModalOpen(false);
    setIsConfirmModalOpen(false);
    setSelectedItem(null);
  };

  const handleViewQRCodes = (visitorId) => {
    navigate(`/visitor/${visitorId}/qr-codes`);
  };

  const handleSave = async (updatedItem) => {
    const token = Cookies.get('token');  // Fetch the token just before saving
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/${dataType}/update`, updatedItem, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setData((prevData) =>
        prevData.map((item) => (item._id === updatedItem._id ? updatedItem : item))
      );
      handleCloseModal();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = async (id) => {
    const token = Cookies.get('token');  // Fetch the token just before deletion
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/${dataType}/delete`, { _id: id }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter((item) => item._id !== id));
      handleCloseModal();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const confirmDelete = (item) => {
    setSelectedItem(item);
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="p-4 bg-white border rounded shadow-lg">
      <div className="mb-6 flex flex-wrap items-center gap-4 p-4 bg-white rounded-xl shadow-lg border border-gray-200">
        {/* Data Type Selector */}
        {isAuthenticated && userRole === 'superadmin' && (
          <select
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            className="p-2 border rounded-lg border-gray-300 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="resident">Users</option>
            <option value="visitor">Visitors</option>
            <option value="property">Properties</option>
            <option value="device">Devices</option>
          </select>
        )}

        {/* Search Input */}
        {(dataType !== 'property' && dataType !== 'device') && (
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by name or email"
            className="p-2 border rounded-lg border-gray-300 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 w-64"
          />
        )}

        {/* Property Filter */}
        {isAuthenticated && userRole === 'superadmin' && dataType !== 'property' && (
          <>
            <select
              value={propertyFilter}
              onChange={(e) => setPropertyFilter(e.target.value)}
              className={`p-2 border rounded-lg border-gray-300 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 ${roleFilter === 'superadmin' ? 'hidden' : ''}`}
            >
              <option value="">Filter by property</option>
              {properties.map((property) => (
                <option key={property._id} value={property._id}>
                  {property.propertyName}
                </option>
              ))}
            </select>

            {/* Role Filter */}
            {dataType === 'resident' && (
              <select
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
                className="p-2 border rounded-lg border-gray-300 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Filter by role</option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Property Manager</option>
                <option value="resident">Resident</option>
              </select>
            )}
          </>
        )}
      </div>

      {/* Render tables based on dataType */}
      <div className="overflow-x-auto border border-gray-200 rounded-xl shadow-lg bg-white">
        {dataType === 'resident' && (
          <table className="min-w-full border-collapse text-sm">
            <thead className="bg-gray-50 text-gray-700 uppercase font-semibold">
              <tr>
                <th className="px-6 py-4 text-left">First Name</th>
                <th className="px-6 py-4 text-left">Last Name</th>
                <th className="px-6 py-4 text-left">Email</th>
                <th className="px-6 py-4 text-left">Role</th>
                <th className="px-6 py-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user, index) => (
                <tr
                  key={user._id}
                  className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    } hover:bg-gray-100 transition-colors`}
                >
                  <td className="px-6 py-4">{user.firstName}</td>
                  <td className="px-6 py-4">{user.lastName}</td>
                  <td className="px-6 py-4">{user.email}</td>
                  <td className="px-6 py-4">
                    {user.role === 'superadmin'
                      ? 'Super User'
                      : user.role === 'admin'
                        ? 'Property Manager'
                        : 'Resident'}
                  </td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleEdit(user)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => confirmDelete(user)}
                      className="ml-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {dataType === 'visitor' && (
          <table className="min-w-full border-collapse text-sm mt-6">
            <thead className="bg-gray-50 text-gray-700 uppercase font-semibold">
              <tr>
                <th className="px-6 py-4 text-left">First Name</th>
                <th className="px-6 py-4 text-left">Last Name</th>
                <th className="px-6 py-4 text-left">Email</th>
                <th className="px-6 py-4 text-center">QR Codes</th>
                <th className="px-6 py-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((visitor, index) => (
                <tr
                  key={visitor._id}
                  className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    } hover:bg-gray-100 transition-colors`}
                >
                  <td className="px-6 py-4">{visitor.firstName}</td>
                  <td className="px-6 py-4">{visitor.lastName}</td>
                  <td className="px-6 py-4">{visitor.email}</td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleViewQRCodes(visitor._id)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
                    >
                      View QR Codes
                    </button>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleEdit(visitor)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => confirmDelete(visitor)}
                      className="ml-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {dataType === 'property' && (
          <table className="min-w-full border-collapse text-sm mt-6">
            <thead className="bg-gray-50 text-gray-700 uppercase font-semibold">
              <tr>
                <th className="px-6 py-4 text-left">Name</th>
                <th className="px-6 py-4 text-left">Location</th>
                <th className="px-6 py-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((property, index) => (
                <tr
                  key={property._id}
                  className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    } hover:bg-gray-100 transition-colors`}
                >
                  <td className="px-6 py-4">{property.propertyName}</td>
                  <td className="px-6 py-4">{property.propertyLocation}</td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleEdit(property)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => confirmDelete(property)}
                      className="ml-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {dataType === 'device' && <DeviceList propertyFilter={propertyFilter} />}

      {isModalOpen && <UserModal item={selectedItem} onSave={handleSave} onClose={handleCloseModal} />}
      {isPropertyModalOpen && <PropertyModal item={selectedItem} onSave={handleSave} onClose={handleCloseModal} />}
      {isVisitorModalOpen && <VisitorModal item={selectedItem} onSave={handleSave} onClose={handleCloseModal} />}
      {isConfirmModalOpen && <ConfirmationModal isOpen={isConfirmModalOpen} onClose={handleCloseModal} onConfirm={handleDelete} item={selectedItem} type={dataType} />}
    </div>
  );
};

export default Dashboard;
