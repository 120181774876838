import React, { useEffect, useState, useCallback } from 'react';
import { FaDoorOpen, FaDoorClosed } from 'react-icons/fa';

const GateController = () => {
  const [gateDevices, setGateDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const getTokenFromCookies = () => {
    const cookieArr = document.cookie.split(';');
    for (let cookie of cookieArr) {
      const [key, value] = cookie.trim().split('=');
      if (key === 'token') return value;
    }
    return null;
  };

  const fetchGateDevices = useCallback(async () => {
    try {
      const token = getTokenFromCookies();
      if (!token) {
        console.log('Authentication token not found. Please log in.');
        return;
      }

      const response = await fetch(`/api/device/get-gate-devices`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch gate devices');
      }

      const data = await response.json();
      setGateDevices(data.gateDevices);
      setLoading(false);
    } catch (err) {
      setError('Failed to load gate devices. Please try again later.');
      setLoading(false);
    }
  }, []);

  const triggerRelay = async (deviceId, deviceName) => {
    try {
      setMessage(`Opening ${deviceName}...`);
      setIsError(false);

      const token = getTokenFromCookies();
      if (!token) {
        console.log('Authentication token not found. Please log in.');
        return;
      }

      const response = await fetch('/api/device/trigger-relay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ deviceId }),
      });

      if (!response.ok) {
        throw new Error('Failed to trigger relay');
      }

      setMessage(`${deviceName} has been opened successfully.`);
      setIsError(false);
    } catch (err) {
      console.error('Error triggering relay:', err);
      setMessage(`${deviceName} has not been opened. Please try again.`);
      setIsError(true);
    }

    setTimeout(() => {
      setMessage('');
      setIsError(false);
    }, 4000);
  };

  useEffect(() => {
    fetchGateDevices();
  }, [fetchGateDevices]);

  return (
    <div className="min-h-screen flex flex-col items-center p-5">
      <h1 className="text-4xl font-bold mb-6 text-left w-full pl-5">Access Gates</h1>
      {message && (
        <div
          className={`mb-4 p-4 w-full max-w-md text-center rounded-lg shadow-md ${isError ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'} animate-fadeIn transition-all duration-1000 ease-in-out`}
        >
          {message}
        </div>
      )}
      {loading ? (
        <p className="text-lg text-gray-700 opacity-0 animate-fadeIn transition-opacity duration-500 ease-in-out">Loading gate devices...</p>
      ) : error ? (
        <p className="text-lg text-red-500">{error}</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
          {gateDevices.map((device) => (
            <div
              key={device.id}
              className="p-6 bg-white shadow-lg rounded-lg flex flex-col items-center text-center border border-gray-200 transform transition-all duration-300 ease-in-out"
            >
              <FaDoorClosed className="text-6xl text-blue-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">{device.deviceName}</h2>

              {/* Conditional rendering for device label */}
              {device.deviceLabel && device.deviceLabel.trim() !== "" && (
                <p className="text-gray-600 mb-4">Label: {device.deviceLabel}</p>
              )}

              {/* Content section to push the button to the bottom */}
              <div className="flex-grow"></div>

              <button
                onClick={() => triggerRelay(device.id, device.deviceName)}
                className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 hover:shadow-lg transition-all duration-300 ease-in-out flex items-center gap-2"
              >
                <FaDoorOpen /> Open Gate
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GateController;
