// src/pages/PasswordResetRequestPage.js
import React from 'react';
import PasswordResetRequest from '../components/forms/PasswordResetRequest';

const PasswordResetRequestPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <PasswordResetRequest />
    </div>
  );
};

export default PasswordResetRequestPage;
