import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const BetweenDays = ({ formData, handleChange, handleChangeDate, dateError }) => {
    const formatTime24 = (date) => {
        if (!date) return '';
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleTimeChange = (date, fieldName) => {
        handleChangeDate(formatTime24(date), fieldName);
    };

    return (
        <div className="mb-3">
            {/* Lifetime Validity Checkbox */}
            <div className="mb-4">
                <input
                    id="lifetimeValidity"
                    type="checkbox"
                    name="lifetimeValidity"
                    checked={formData.lifetimeValidity}
                    onChange={handleChange}
                    className="mr-2"
                />
                <span className="text-gray-700 text-sm font-semibold mb-2">
                    Check for Permanent Visitor
                </span>
            </div>

            {/* Start Date and Time */}
            <div className="mb-4 flex flex-row sm:items-center lg:gap-4 gap-1">
                <div className="flex-1">
                    <label
                        htmlFor="startDate"
                        className="block text-gray-700 text-sm font-semibold mb-2"
                    >
                        Start Date
                    </label>
                    <DatePicker
                        id="startDate"
                        selected={formData.startDate}
                        onChange={(date) => handleChangeDate(date, 'startDate')}
                        dateFormat="MM/dd/yyyy"
                        className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                        onKeyDown={(e) => e.preventDefault()} // Prevents typing
                        autoComplete="off" // Disables autocomplete
                    />
                </div>

                <div className="flex-1">
                    <label
                        htmlFor="startTime"
                        className="block text-gray-700 text-sm font-semibold mb-2"
                    >
                        Start Time
                    </label>
                    <DatePicker
                        id="startTime"
                        selected={formData.startTime ? new Date(`1970-01-01T${formData.startTime}:00`) : null}
                        onChange={(date) => handleTimeChange(date, 'startTime')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                        className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                        onKeyDown={(e) => e.preventDefault()} // Prevents typing
                        autoComplete="off" // Disables autocomplete
                    />
                </div>
            </div>

            {/* End Date and Time (only if lifetimeValidity is false) */}
            {!formData.lifetimeValidity && (
                <>
                    <div className="mb-4 flex flex-row sm:items-center lg:gap-4 gap-1">
                        <div className="flex-1">
                            <label
                                htmlFor="endDate"
                                className="block text-gray-700 text-sm font-semibold mb-2"
                            >
                                End Date
                            </label>
                            <DatePicker
                                id="endDate"
                                selected={formData.endDate}
                                onChange={(date) => handleChangeDate(date, 'endDate')}
                                dateFormat="MM/dd/yyyy"
                                className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                                onKeyDown={(e) => e.preventDefault()} // Prevents typing
                                autoComplete="off" // Disables autocomplete
                            />
                        </div>

                        <div className="flex-1">
                            <label
                                htmlFor="endTime"
                                className="block text-gray-700 text-sm font-semibold mb-2"
                            >
                                End Time
                            </label>
                            <DatePicker
                                id="endTime"
                                selected={formData.endTime ? new Date(`1970-01-01T${formData.endTime}:00`) : null}
                                onChange={(date) => handleTimeChange(date, 'endTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                timeCaption="Time"
                                dateFormat="hh:mm aa"
                                className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                                onKeyDown={(e) => e.preventDefault()} // Prevents typing
                                autoComplete="off" // Disables autocomplete
                            />
                        </div>
                    </div>

                    {/* Error Message */}
                    {dateError && (
                        <p className="text-red-700 text-sm mb-4 rounded px-2 py-2 bg-red-200 w-full">
                            {dateError}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default BetweenDays;
