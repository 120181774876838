import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const RegisterDevice = () => {
    const [deviceName, setDeviceName] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [deviceLabel, setDeviceLabel] = useState('');
    const [deviceIpAddress, setDeviceIpAddress] = useState('');
    const [propertyId, setPropertyId] = useState('');
    const [properties, setProperties] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showSuccessNotification, setShowSuccessNotification] = useState(false); // Notification state

    const token = Cookies.get('token');

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.role !== 'superadmin') {
                    setRedirect(true);
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                setError('Invalid token');
                setRedirect(true);
            }
        } else {
            setRedirect(true);
        }
    }, [token]);

    useEffect(() => {
        // Fetch properties from the backend
        const fetchProperties = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/get-all`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setProperties(response.data || []);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };

        fetchProperties();
    }, [token]);

    const handleRegisterDevice = async (e) => {
        e.preventDefault();

        // Validate required fields
        const errors = {};

        if (!deviceName) errors.deviceName = 'Device Name is required';
        if (!deviceType) errors.deviceType = 'Device Type is required';
        if (!deviceIpAddress) errors.deviceIpAddress = 'Device IP Address is required';
        if (!propertyId) errors.propertyId = 'Property is required';

        setFormErrors(errors); // Set validation errors

        // If there are errors, don't submit the form
        if (Object.keys(errors).length > 0) {
            return;
        }

        setIsSubmitting(true); // Disable submit button
        setError(''); // Clear previous errors

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/device/register`, {
                deviceName,
                deviceType,
                deviceLabel,
                deviceIpAddress, // Include the new IP address field
                propertyId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 201) {
                console.log("Registered device successfully");
                setSuccess('Device registered successfully');
                
                // Show success notification
                setShowSuccessNotification(true);

                // Clear inputs except device label and reset dropdowns
                setDeviceName('');
                setDeviceType('');
                setDeviceIpAddress('');
                setPropertyId('');
                setDeviceLabel(''); // Optional field, leave it empty

                // Hide the success notification after 5 seconds
                setTimeout(() => {
                    setShowSuccessNotification(false);
                }, 5000); // 5 seconds
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Registration failed');
        } finally {
            setIsSubmitting(false); // Re-enable submit button
        }
    };

    if (redirect) {
        return <Navigate to="/" replace />;
    }

    return (
        <div className="mx-auto max-w-lg">
            <div className="bg-slate-100 border p-8 rounded-lg shadow-lg w-full">
                <h2 className="text-2xl font-bold text-center mb-6">Register Device</h2>

                {/* Success Notification */}
                {showSuccessNotification && (
                    <div className="bg-green-300 text-white font-bold p-3 rounded-lg mb-4 shadow-lg">
                        <strong>Success!</strong> {success}
                    </div>
                )}

                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <form onSubmit={handleRegisterDevice}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceName">
                            Device Name
                        </label>
                        <input
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.deviceName ? 'border-red-500' : ''}`}
                            id="deviceName"
                            type="text"
                            placeholder="Enter device name"
                            value={deviceName}
                            onChange={(e) => setDeviceName(e.target.value)}
                            required
                        />
                        {formErrors.deviceName && <p className="text-red-500 text-xs">{formErrors.deviceName}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceLabel">
                            Device Label (Optional)
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="deviceLabel"
                            type="text"
                            placeholder="Enter device label"
                            value={deviceLabel}
                            onChange={(e) => setDeviceLabel(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceType">
                            Device Type
                        </label>
                        <select
                            id="deviceType"
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.deviceType ? 'border-red-500' : ''}`}
                            value={deviceType}
                            onChange={(e) => setDeviceType(e.target.value)}
                            required
                        >
                            <option value="">Select a Device Type</option>
                            <option value="gate">Gate</option>
                            {/* <option value="pool">Swimming Pool</option>
                            <option value="library">Library</option> */}
                        </select>
                        {formErrors.deviceType && <p className="text-red-500 text-xs">{formErrors.deviceType}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceIpAddress">
                            Device IP Address
                        </label>
                        <input
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.deviceIpAddress ? 'border-red-500' : ''}`}
                            id="deviceIpAddress"
                            type="text"
                            placeholder="Enter device IP address"
                            value={deviceIpAddress}
                            onChange={(e) => setDeviceIpAddress(e.target.value)}
                            required
                        />
                        {formErrors.deviceIpAddress && <p className="text-red-500 text-xs">{formErrors.deviceIpAddress}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="propertyId">
                            Property
                        </label>
                        <select
                            id="propertyId"
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.propertyId ? 'border-red-500' : ''}`}
                            value={propertyId}
                            onChange={(e) => setPropertyId(e.target.value)}
                            required
                        >
                            <option value="">Select a Property</option>
                            {properties.map(property => (
                                <option key={property._id} value={property._id}>
                                    {`${property.propertyName} (${property.propertyLocation})`}
                                </option>
                            ))}
                        </select>
                        {formErrors.propertyId && <p className="text-red-500 text-xs">{formErrors.propertyId}</p>}
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                            disabled={isSubmitting} // Disable submit while submitting
                        >
                            {isSubmitting ? 'Registering...' : 'Register Device'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterDevice;
