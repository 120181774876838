// src/pages/PasswordSetPage.js
import React from 'react';
import PasswordSet from '../components/forms/PasswordSet';

const PasswordSetPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <PasswordSet />
    </div>
  );
};

export default PasswordSetPage;