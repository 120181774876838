// src/pages/RegisterDevicePage.js
import React from 'react';
import RegisterDevice from '../../components/forms/RegisterDevice';

const RegisterDevicePage = () => {
  return (
    <div className="container mx-auto mt-2">
      <RegisterDevice />
    </div>
  );
};

export default RegisterDevicePage;
