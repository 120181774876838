import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RecurringDays from '../forms/components/RecurringDays';
import MultipleDates from '../forms/components/MultipleDates';

const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
};

const VisitorsList = () => {
    const [visitors, setVisitors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [editedQrCode, setEditedQrCode] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageType, setSuccessMessageType] = useState(''); // To distinguish types (e.g., 'delete' or 'update')
    const [confirmationPopup, setConfirmationPopup] = useState(null); // For delete confirmation popup

    const [hasRecurringDaysErrors, setHasRecurringDaysErrors] = useState(false);
    const handleRecurringDaysValidation = (isInvalid) => {
        setHasRecurringDaysErrors(isInvalid);
        if (isInvalid) {
            setErrorMessage('Please correct the recurring days errors.');
        }
    };

    // eslint-disable-next-line
    const [hasMultipleDatesErrors, setHasMultipleDatesErrors] = useState(false);
    const handleMultipleDatesValidation = useCallback((isInvalid) => {
        setHasMultipleDatesErrors(isInvalid);
        if (isInvalid) {
            setErrorMessage('Please correct the multiple dates errors.');
        }
        else {
            setErrorMessage('');
        }
        console.log('Multiple Dates Validation:', isInvalid);

    }, []);

    const token = Cookies.get('token');

    useEffect(() => {
        const fetchVisitors = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/visitor/visitors-qr`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setVisitors(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch visitors');
                setLoading(false);
            }
        };

        fetchVisitors();
    }, [token]); // Only re-run when `token` changes    

    const validateDates = useCallback(() => {
        if (!editedQrCode) return;

        const { startDate, endDate } = editedQrCode;

        if (startDate && endDate) {
            if (startDate >= endDate) {
                setErrorMessage('End date must be after start date.');
            } else {
                setErrorMessage('');
            }
        } else {
            setErrorMessage('');
        }
    }, [editedQrCode]);

    useEffect(() => {
        if (editedQrCode && editedQrCode.qrType !== 'randomDates') {
            validateDates();
        }
    }, [editedQrCode, validateDates]);

    const handleEditQrCode = (visitorId, qrCode) => {
        setEditedQrCode({
            visitorId,
            _id: qrCode._id,
            startDate: new Date(qrCode.startDate),
            endDate: qrCode.lifetimeValidity ? null : new Date(qrCode.endDate),
            lifetimeValidity: qrCode.lifetimeValidity,
            qrType: qrCode.qrType,
            multipleDays: qrCode.multipleDays,
            recurringDays: qrCode.recurringDays,
        });
        setErrorMessage('');
    };

    const handleChange = useCallback((event) => {
        const { name, value } = event.target;
        setEditedQrCode((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleDateChange = useCallback((field, date) => {
        setEditedQrCode(prevState => ({
            ...prevState,
            [field]: date
        }));
    }, []);


    const handleLifetimeValidityChange = (checked) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0); // Set to 12 AM

        if (checked && editedQrCode.qrType !== 'randomDates') {
            if (hasRecurringDaysErrors) {
                setErrorMessage('Please correct the recurring days errors.');
                return;
            }
            else {
                setErrorMessage('');
            }
        }
        else {
            setErrorMessage('');
        }

        setEditedQrCode(prevState => ({
            ...prevState,
            lifetimeValidity: checked,
            startDate: checked ? prevState.startDate : new Date(today.setHours(0, 0, 0, 0)), // Set start date to today at 12 AM
            endDate: checked ? null : tomorrow // Set end date to tomorrow at 12 AM
        }));
    };

    // Function to show a success message with a timeout
    const showSuccessMessage = (message, type = '') => {
        setSuccessMessage(message);
        setSuccessMessageType(type);
        setTimeout(() => {
            setSuccessMessage('');
            setSuccessMessageType('');
        }, 2000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!!errorMessage) return;

        try {
            let startDateUTC = editedQrCode.startDate.toISOString();
            let endDateUTC = editedQrCode.lifetimeValidity
                ? new Date(editedQrCode.startDate).setFullYear(new Date().getFullYear() + 100)
                : editedQrCode.endDate.toISOString();

            // Sort `recurringDays` by day order
            const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            const sortedRecurringDays = (editedQrCode.recurringDays || []).sort(
                (a, b) => daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day)
            );

            // Sort `multipleDays` by date
            const sortedMultipleDays = (editedQrCode.multipleDays || []).sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            );

            // Conditionally update start and end dates if qrType is "multipleDays"
            if (editedQrCode.qrType === 'randomDates' && sortedMultipleDays.length > 0) {
                startDateUTC = `${sortedMultipleDays[0].date}T${sortedMultipleDays[0].startTime}`;
                endDateUTC = `${sortedMultipleDays[sortedMultipleDays.length - 1].date}T${sortedMultipleDays[sortedMultipleDays.length - 1].endTime}`;
            }

            await axios.put(`${process.env.REACT_APP_API_URL}/api/visitor/visitors-qr/${editedQrCode.visitorId}/update-qrcodes`, {
                _id: editedQrCode._id,
                startDate: startDateUTC,
                endDate: endDateUTC,
                lifetimeValidity: editedQrCode.lifetimeValidity,
                recurringDays: sortedRecurringDays,
                multipleDays: sortedMultipleDays,
                qrType: editedQrCode.qrType,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/visitor/visitors-qr`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setVisitors(response.data);
            setEditedQrCode(null);
            showSuccessMessage('QR code updated successfully.', 'update');
        } catch (err) {
            setError(`Failed to update QR code: ${err.response ? err.response.data.message : err.message}`);
        }
    };

    const expireQRCode = async (visitorId, qrCodeId) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/visitor/expire-qrcode`,
                { qrCodeId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/visitor/visitors-qr`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setVisitors(response.data);
            showSuccessMessage('QR code deleted successfully.', 'delete');
        } catch (err) {
            setError('Failed to expire QR code');
        }
    };

    // Helper function to convert time to 12-hour AM/PM format
    const formatTimeTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleTimeString('en-US', options); // 'en-US' locale uses 12-hour format with AM/PM
    };

    const confirmDelete = (visitorId, qrCodeId) => {
        setConfirmationPopup({ visitorId, qrCodeId });
    };

    const handleCancelDelete = () => {
        setConfirmationPopup(null);
    };

    if (loading) return <div className="text-center text-gray-700">Loading...</div>;
    if (error) return <div className="text-center text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-4xl font-bold mb-8 text-gray-800">Visitor List</h1>
            {visitors.length === 0 ? (
                <p className="text-center text-gray-600">No visitors found</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {visitors.map((visitor) => (
                        <div key={visitor._id} className="bg-white transition border border-gray-200 rounded shadow-lg p-3 duration-300 ease-in-out">
                            
                            {/* Inner container with border */}
                            <div className="border shadow-lg rounded-lg p-6 border-gray-200 hover:shadow-xl">
                                {/* Visitor Information Section */}
                                <div className="mb-6">
                                    <p className="text-xl font-semibold text-gray-800">Visitor: {visitor.email}</p>
                                </div>
    
                                {/* QR Code Details Section */}
                                <div>
                                    <h3 className="text-lg font-medium text-gray-800 mb-4">QR Code Details</h3>
    
                                    {visitor.qrCodes.map((qr) => (
                                        <div key={qr._id} className="mt-6 bg-slate-200 p-4 rounded-lg shadow-md border border-gray-200">
    
                                            {/* QR Code General Information */}
                                            <div className="space-y-2">
                                                <h2 className="text-xl font-semibold text-gray-800">{qr.qrFirstName} {qr.qrLastName}</h2>
                                                {qr.qrType !== 'randomDates' && (
                                                    <>
                                                        <p className="text-gray-600">Start Date: <span className="font-medium text-gray-800">{formatDate(qr.startDate)}</span></p>
                                                        <p className="text-gray-600">End Date: <span className="font-medium text-gray-800">{qr.lifetimeValidity ? 'Permanent Visitor' : formatDate(qr.endDate)}</span></p>
                                                    </>
                                                )}
                                                <p className="text-gray-600">Created At: <span className="font-medium text-gray-800">{formatDate(qr.createdAt)}</span></p>
                                            </div>
    
                                            {/* QR Type-Specific Information */}
                                            {qr.qrType === 'weekdays' && (
                                                <div className="mt-4 space-y-2 rounded p-4 bg-white">
                                                    <p className="text-gray-600 font-semibold">Recurring Days:</p>
                                                    {qr.recurringDays.map((day) => (
                                                        <p key={day._id} className="text-gray-600">
                                                            {day.day}: <span className="font-medium">{formatTimeTo12Hour(day.startTime)} - {formatTimeTo12Hour(day.endTime)}</span>
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
    
                                            {qr.qrType === 'randomDates' && (
                                                <div className="mt-4 space-y-2 rounded p-4 bg-white">
                                                    <p className="text-gray-600 font-semibold">Multiple Dates:</p>
                                                    {qr.multipleDays.map((day) => (
                                                        <p key={day._id} className="text-gray-600">
                                                            {day.date}: <span className="font-medium">{formatTimeTo12Hour(day.startTime)} - {formatTimeTo12Hour(day.endTime)}</span>
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
    
                                            {/* Buttons Section */}
                                            <div className="mt-6 flex mx-auto gap-4">
                                                <button
                                                    onClick={() => handleEditQrCode(visitor._id, JSON.parse(JSON.stringify(qr)))}
                                                    className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-200 transform hover:scale-105">
                                                    Edit Visitor Time
                                                </button>
    
                                                <button
                                                    onClick={() => confirmDelete(visitor._id, qr._id)}
                                                    className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition duration-200 transform hover:scale-105">
                                                    Delete QR Code
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
    
            {/* Editing QR Code Form */}
            {editedQrCode && (
                <div className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-50 z-50 overflow-scroll p-4">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">Edit QR Code</h2>
                        <form onSubmit={handleSubmit}>
                            {editedQrCode.qrType !== 'randomDates' && (
                                <>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 font-medium mb-2">Start Date:</label>
                                        <DatePicker
                                            timeIntervals={60}
                                            selected={editedQrCode.startDate}
                                            onChange={(date) => handleDateChange('startDate', date)}
                                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            showTimeSelect
                                            dateFormat="Pp"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 font-medium mb-2">End Date:</label>
                                        <DatePicker
                                            timeIntervals={60}
                                            selected={editedQrCode.endDate}
                                            onChange={(date) => handleDateChange('endDate', date)}
                                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            showTimeSelect
                                            dateFormat="Pp"
                                            disabled={editedQrCode.lifetimeValidity}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="flex items-center text-gray-700 font-medium">
                                            <input
                                                type="checkbox"
                                                checked={editedQrCode.lifetimeValidity}
                                                onChange={(e) => handleLifetimeValidityChange(e.target.checked)}
                                                className="mr-2"
                                            />
                                            Permanent Visitor
                                        </label>
                                    </div>
                                </>
                            )}
                            {editedQrCode.qrType === 'weekdays' && (
                                <RecurringDays formData={editedQrCode} handleChange={handleChange} onValidationChange={handleRecurringDaysValidation} />
                            )}
                            {editedQrCode.qrType === 'randomDates' && (
                                <MultipleDates formData={editedQrCode} handleChange={handleChange} onValidationChange={handleMultipleDatesValidation} />
                            )}
                            {/* Error Message Display */}
                            {errorMessage && (
                                <div className="p-2 mb-4 bg-red-200 text-red-800 border border-red-400 rounded w-full">
                                    {errorMessage}
                                </div>
                            )}
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setEditedQrCode(null)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-200 mr-2">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200"
                                    disabled={!!errorMessage}>
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
    
            {/* Confirmation Popup for Deleting QR Code */}
            {confirmationPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
                        <h2 className="text-xl font-bold text-gray-800 mb-4">Confirm Deletion</h2>
                        <p className="text-gray-700 mb-6">Are you sure you want to delete this QR code?</p>
                        <div className="flex justify-end">
                            <button
                                onClick={handleCancelDelete}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-200 mr-2">
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    expireQRCode(confirmationPopup.visitorId, confirmationPopup.qrCodeId);
                                    setConfirmationPopup(null);
                                }}
                                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
    
            {/* Success Message */}
            {successMessage && (
                <div className={`fixed bottom-0 right-0 m-6 p-4 rounded shadow-lg transition-all duration-300 ${successMessageType === 'update' ? 'bg-green-600' : 'bg-red-600'
                    }`}>
                    <p className="text-white text-lg">{successMessage}</p>
                </div>
            )}
        </div>
    );
    
};

export default VisitorsList;
