import React from 'react';
// import { ReactComponent as EmailIcon } from '../assets/svg/email.svg';
// import { ReactComponent as FacebookIcon } from '../assets/svg/facebook.svg';
// import { ReactComponent as LinkedInIcon } from '../assets/svg/linkedin.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
    <div className='border-0 border-t-2'></div>
    <footer className="bg-white text-gray-800 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/* Company Info */}
          <div className="w-full lg:w-1/3 mb-6 lg:mb-0 flex flex-col text-center lg:text-left">
            <h4 className="text-xl text-blue-800 mb-4">Bay Business Edge</h4>
            <p className="text-gray-700">Bay Business Edge, LLC.</p>
            <p className="text-gray-700">10300 49th St. N.</p>
            <p className="text-gray-700">Suite 110</p>
            <p className="text-gray-700">Clearwater, FL 33762</p>
            <p className="text-gray-900 mt-4">
              <span className="text-gray-900">License:</span> ES12002304
            </p>
          </div>

          {/* Logo */}
          <div className="w-full lg:w-1/3 flex justify-center mb-6 lg:mb-0 items-start">
            <a href="/">
              <img
                src="https://lirp.cdn-website.com/3e449f6f/dms3rep/multi/opt/Bay_Logo-458w.png"
                alt="Bay Business Edge Logo"
                className="h-16"
              />
            </a>
          </div>

          {/* Contact and Social Media */}
          {/* <div className="w-full lg:w-1/3 mb-6 lg:mb-0 text-center lg:text-right flex flex-col justify-start">
            <h4 className="text-xl text-blue-800 mb-4">Contact Bay Business Edge, LLC.</h4>

            <p className="mb-4 flex justify-center lg:justify-end items-center">
              <a href="tel:888-333-9340" className="text-gray-700">
              123-258-1212
              </a>
            </p>

            <p className="text-gray-900 mb-4">Follow us On</p>
            <div className="flex justify-center items-center lg:justify-end space-x-4">
              <a
                href="mailto:paul@baybusinessedge.com"
                aria-label="email"
                className="text-gray-700 hover:text-gray-900"
              >
                <EmailIcon className="w-9 h-9" />
              </a>
              <a
                href="https://www.facebook.com/p/Bay-Business-Edge-Inc-100067575766976/?paipv=0&amp;eav=AfZu5NSf6MRGZMPE7-_V2HToZ32DANrCUCKWcaSgu1HDHNehs3p3wUrF9NJolMK4Ux0&amp;_rdr"
                aria-label="facebook"
                className="text-gray-700 hover:text-gray-900"
              >
                <FacebookIcon className="w-7 h-7" />
              </a>
              <a
                href="https://www.linkedin.com/in/bbe-paul-diblasi/"
                aria-label="linkedin"
                className="text-gray-700 hover:text-gray-900"
              >
                <LinkedInIcon className="w-9 h-9" />
              </a>
            </div>
          </div> */}
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 text-center text-sm text-gray-600">
          <p>© {currentYear} Bay Business Edge, LLC. All Rights Reserved | Privacy Policy</p>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
