import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MultipleDates = ({ formData, handleChange, onValidationChange }) => {
  const [selectedDates, setSelectedDates] = useState(formData.multipleDays || []);
  const [rerenderKey, setRerenderKey] = useState(0); // Key for forcing re-render

  const normalizeDate = (date) => {
    const normalizedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return normalizedDate;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const formatTime24 = (date) => {
    if (!date) return "";
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const handleDateChange = (date) => {
    const normalizedDate = normalizeDate(date);
    const formattedDate = normalizedDate.toISOString().split("T")[0];

    const existingDateIndex = selectedDates.findIndex((d) => d.date === formattedDate);

    let updatedDates;
    if (existingDateIndex > -1) {
      updatedDates = selectedDates.filter((_, index) => index !== existingDateIndex);
    } else {
      updatedDates = [
        ...selectedDates,
        { date: formattedDate, startTime: "00:00", endTime: "23:59" },
      ];
    }

    setSelectedDates(updatedDates);
    setRerenderKey((prevKey) => prevKey + 1); // Force re-render
  };

  const updateTime = (index, field, value) => {
    const updatedDates = [...selectedDates];
    updatedDates[index][field] = value;
    setSelectedDates(updatedDates);
  };

  const validateTimes = (startTime, endTime) => {
    return new Date(`1970-01-01T${startTime}`) <= new Date(`1970-01-01T${endTime}`);
  };

  // Effect to update formData and notify parent about validation status
  useEffect(() => {
    handleChange({ target: { name: "multipleDays", value: selectedDates } });

    const hasInvalidTimes = selectedDates.some(
      (entry) => !validateTimes(entry.startTime, entry.endTime)
    );

    onValidationChange(hasInvalidTimes);
  }, [selectedDates, handleChange, onValidationChange]);

  return (
    <div className="mb-3">
      <label className="block text-gray-700 text-sm font-semibold mb-2">
        Select Multiple Dates
      </label>
      <DatePicker
        key={rerenderKey} // Force re-render when rerenderKey changes
        selected={null}
        onChange={(date) => handleDateChange(date)}
        dateFormat="yyyy-MM-dd"
        isClearable
        className="w-full px-3 py-2 border border-gray-300 rounded-lg mb-4"
        inline
        highlightDates={selectedDates.map((entry) => normalizeDate(new Date(entry.date)))}
      />

      {selectedDates.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Selected Dates:</h3>
          <ul className="space-y-4">
            {selectedDates.map((entry, index) => (
              <li key={index} className="border border-gray-300 rounded-lg p-4 bg-gray-50">
                <div className="flex justify-between items-center mb-3">
                  <span className="font-medium">{formatDate(entry.date)}</span>
                  <button
                    onClick={() => handleDateChange(new Date(entry.date))}
                    className="text-red-500 text-sm hover:underline"
                  >
                    Remove
                  </button>
                </div>

                <div className="flex flex-col sm:flex-row sm:items-center sm:gap-4 gap-2">
                  <div className="flex-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-1">
                      Start Time
                    </label>
                    <DatePicker
                      selected={entry.startTime ? new Date(`1970-01-01T${entry.startTime}:00`) : null}
                      onChange={(date) => updateTime(index, "startTime", formatTime24(date))}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                      onKeyDown={(e) => e.preventDefault()} // Prevents typing
                      autoComplete="off"
                    />
                  </div>

                  <div className="flex-1">
                    <label className="block text-gray-700 text-sm font-semibold mb-1">
                      End Time
                    </label>
                    <DatePicker
                      selected={entry.endTime ? new Date(`1970-01-01T${entry.endTime}:00`) : null}
                      onChange={(date) => updateTime(index, "endTime", formatTime24(date))}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                      className={`w-full px-3 py-2 border ${
                        validateTimes(entry.startTime, entry.endTime)
                          ? "border-gray-300"
                          : "border-red-500"
                      } rounded-lg`}
                      onKeyDown={(e) => e.preventDefault()} // Prevents typing
                      autoComplete="off"
                    />
                  </div>
                </div>

                {!validateTimes(entry.startTime, entry.endTime) && (
                  <p className="text-red-500 text-sm mt-2">
                    Start time cannot be after the end time.
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultipleDates;
