// src/pages/AccessGatePage.js
import React from 'react';
import AccessGate from '../components/AccessGate';

const AccessGatePage = () => {
  return (
    <div className="container mx-auto mt-2">
      <AccessGate />
    </div>
  );
};

export default AccessGatePage;
