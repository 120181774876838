// src/pages/SetNewPasswordPage.js
import React from 'react';
import SetNewPassword from '../components/forms/SetNewPassword';

const SetNewPasswordPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <SetNewPassword />
    </div>
  );
};

export default SetNewPasswordPage;