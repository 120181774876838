import React, { useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RecurringDays = ({ formData, handleChange, onValidationChange }) => {
  // Memoize the recurringDays array to avoid unnecessary re-renders
  const recurringDays = useMemo(() => formData.recurringDays || [], [formData.recurringDays]);

  // Utility function to format the time in 24-hour format
  const formatTime24 = (date) => {
    if (!date) return '';
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Time change handler to format in 24-hour time
  const handleTimeChange = (date, field, day) => {
    const formattedTime = formatTime24(date);
    updateTime(day, field, formattedTime);
  };

  const updateTime = (day, field, value) => {
    const existingDayIndex = recurringDays.findIndex((d) => d.day === day);

    if (existingDayIndex > -1) {
      const updatedDays = [...recurringDays];
      updatedDays[existingDayIndex][field] = value;
      handleChange({ target: { name: "recurringDays", value: updatedDays } });
    }
  };

  const validateTimes = (startTime, endTime) => {
    return new Date(`1970-01-01T${startTime}`) <= new Date(`1970-01-01T${endTime}`);
  };

  // Check if any invalid times exist and notify parent
  useEffect(() => {
    const hasInvalidTimes = recurringDays.some(
      (day) => !validateTimes(day.startTime, day.endTime)
    );
    onValidationChange(hasInvalidTimes);
  }, [recurringDays, onValidationChange]);

  const toggleDay = (day) => {
    const existingDayIndex = recurringDays.findIndex((d) => d.day === day);

    let updatedDays;
    if (existingDayIndex > -1) {
      updatedDays = recurringDays.filter((_, index) => index !== existingDayIndex);
    } else {
      updatedDays = [
        ...recurringDays,
        { day, startTime: "00:00", endTime: "23:59" },
      ];
    }

    handleChange({ target: { name: "recurringDays", value: updatedDays } });
  };

  return (
    <div className="mb-3">
      <label className="block text-gray-700 text-sm font-semibold mb-4">
        Select Recurring Weekdays and Times
      </label>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => {
          const dayEntry = recurringDays.find((entry) => entry.day === day);

          return (
            <div
              key={day}
              className="flex flex-col gap-2 items-start p-4 bg-white border border-gray-300 rounded-lg shadow-sm"
            >
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id={day}
                  checked={!!dayEntry}
                  onChange={() => toggleDay(day)}
                  className="h-5 w-5 text-blue-600 focus:ring focus:ring-blue-300 rounded"
                />
                <label htmlFor={day} className="text-sm font-medium text-gray-700">
                  {day}
                </label>
              </div>

              {dayEntry && (
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-col items-center">
                    <label
                      htmlFor={`startTime_${day}`}
                      className="text-xs text-gray-600 mb-1"
                    >
                      Start Time
                    </label>
                    <DatePicker
                      id={`startTime_${day}`}
                      selected={dayEntry.startTime ? new Date(`1970-01-01T${dayEntry.startTime}:00`) : null}
                      onChange={(date) => handleTimeChange(date, "startTime", day)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="hh:mm aa" // 12-hour format with AM/PM
                      className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                      onKeyDown={(e) => e.preventDefault()} // Prevents typing
                      autoComplete="off" // Disables autocomplete
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <label
                      htmlFor={`endTime_${day}`}
                      className="text-xs text-gray-600 mb-1"
                    >
                      End Time
                    </label>
                    <DatePicker
                      id={`endTime_${day}`}
                      selected={dayEntry.endTime ? new Date(`1970-01-01T${dayEntry.endTime}:00`) : null}
                      onChange={(date) => handleTimeChange(date, "endTime", day)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="hh:mm aa" // 12-hour format with AM/PM
                      className={`w-full px-2 h-10 sm:px-3 py-2 border ${
                        validateTimes(dayEntry.startTime, dayEntry.endTime)
                          ? "border-gray-300"
                          : "border-red-500"
                      } rounded-lg`}
                      onKeyDown={(e) => e.preventDefault()} // Prevents typing
                      autoComplete="off" // Disables autocomplete
                    />
                  </div>
                  {!validateTimes(dayEntry.startTime, dayEntry.endTime) && (
                    <p className="text-red-500 text-sm mt-2">
                      Start time cannot be after the end time.
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecurringDays;
