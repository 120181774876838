import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import MultipleDates from './components/MultipleDates';
import RecurringDays from './components/RecurringDays';
import BetweenDays from './components/BetweenDays';

// Bind modal to your app element
Modal.setAppElement('#root');

const RegisterForm = () => {
  // Define initial form data
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // Set end date to tomorrow
    startTime: '00:00',
    endTime: '00:00',
    PID: '',
    lifetimeValidity: false,
    qrType: 'betweenDates',
    multipleDays: [],
    recurringDays: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [tokenPID, setTokenPID] = useState('');
  const [dateError, setDateError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalContent, setModalContent] = useState({
    isOpen: false,
    message: '',
    email: '',
    residentEmail: '',
    residentFirstName: '',
    firstName: ''
  });

  const [hasRecurringDaysErrors, setHasRecurringDaysErrors] = useState(false);
  const handleRecurringDaysValidation = (isInvalid) => {
    setHasRecurringDaysErrors(isInvalid);
    if (isInvalid) {
      setErrorMessage('Please correct the recurring days errors.');
    } else {
      setErrorMessage('');
    }
    console.log('Recurring Days Validation:', isInvalid);
  };
  
  // eslint-disable-next-line
  const [hasMultipleDatesErrors, setHasMultipleDatesErrors] = useState(false);
  const handleMultipleDatesValidation = useCallback((isInvalid) => {
    setHasMultipleDatesErrors(isInvalid);
    if (isInvalid) {
      setErrorMessage('Please correct the multiple dates errors.');
    } else {
      setErrorMessage('');
    }
    console.log('Multiple Dates Validation:', isInvalid);
  }, []);

  useEffect(() => {
    const setDefaultDates = () => {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      setFormData(prevState => ({
        ...prevState,
        startDate: today,
        endDate: tomorrow
      }));
    };

    const fetchProperties = async () => {
      try {
        const token = Cookies.get('token');

        if (!token) {
          return;
        }

        const decodedToken = jwtDecode(token);
        setIsSuperAdmin(decodedToken.role === 'superadmin');
        setTokenPID(decodedToken.PID || '');

        if (decodedToken.role === 'superadmin') {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/property/list-user-properties`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          setProperties(response.data.properties);
        }

        setDefaultDates();
      } catch (error) {
        console.error('Error fetching properties or decoding token:', error);
      }
    };

    fetchProperties();
  }, []);

  const validateDates = (startDate, startTime, endDate, endTime) => {
    const start = new Date(`${format(startDate, 'yyyy-MM-dd')}T${startTime}`);
    const end = new Date(`${format(endDate, 'yyyy-MM-dd')}T${endTime}`);

    if (start >= end) {
      setDateError('End Date and Time cannot be earlier than Start Date and Time');
      return false;
    }
    setDateError('');
    return true;
  };


  const handleChangeDate = useCallback((date, name) => {
    setFormData(prevState => {
      const updatedData = { ...prevState, [name]: date };

      if (name === 'startDate' || name === 'endDate') {
        validateDates(updatedData.startDate, updatedData.startTime, updatedData.endDate, updatedData.endTime);
      }

      return updatedData;
    });
  }, []); // No dependencies since this function doesn't rely on any external variables

  const highlightText = (text, keywords) => {
    const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
    return text.split(regex).map((part, index) =>
      keywords.some(keyword => keyword.toLowerCase() === part.toLowerCase()) ? (
        <span key={index} style={{ color: 'blue' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'qrType') {
      // Reset the error message and set initial form data
      setErrorMessage('');
      setFormData(prevFormData => ({
        ...prevFormData,
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        startTime: '00:00',
        endTime: '00:00',
        PID: '',
        lifetimeValidity: false,
        qrType: 'betweenDates',
        multipleDays: [],
        recurringDays: []
      }));
      setFormData(prevState => ({
        ...prevState,
        qrType: value
      }));
    }

    if (name === 'lifetimeValidity') {
      const today = new Date();
      const tomorrow = new Date();

      if (checked && value !== 'randomDates') {
        if (hasRecurringDaysErrors) {
          setErrorMessage('Please correct the recurring days errors.');
          return;
        }
        else {
          setErrorMessage('');
        }
      }
      else {
        setDateError('');
      }

      // Handle the lifetime validity logic
      if (checked) {
        console.log(today);
        tomorrow.setDate(today.getDate() + 365 * 100); // 100 years validity
      } else {
        console.log(today);
        tomorrow.setDate(today.getDate() + 1); // Default to 1-day validity
      }

      console.log(tomorrow);

      setFormData(prevState => ({
        ...prevState,
        lifetimeValidity: checked,
        startDate: checked ? today : prevState.startDate,
        startTime: checked ? '00:00' : prevState.startTime,
        endDate: tomorrow,
        endTime: '00:00'
      }));
    } else {
      // Handle other form fields
      setFormData(prevState => {
        const updatedData = {
          ...prevState,
          [name]: type === 'checkbox' ? checked : value
        };

        // Validate date and time fields if necessary
        if (['startDate', 'endDate', 'startTime', 'endTime'].includes(name)) {
          validateDates(updatedData.startDate, updatedData.startTime, updatedData.endDate, updatedData.endTime);
        }

        return updatedData;
      });
    }
  }, [hasRecurringDaysErrors]); // No dependencies, since it's only using state set functions that are stable

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Validate date and time
    if (!validateDates(formData.startDate, formData.startTime, formData.endDate, formData.endTime)) {
      setIsLoading(false);
      return;
    }

    const token = Cookies.get('token');

    if (!token) {
      setIsLoading(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const UID = decodedToken._id;

    // Initialize startDateTime and endDateTime
    let startDate = `${format(formData.startDate, 'yyyy-MM-dd')}T${formData.startTime}`;
    let endDate = `${format(formData.endDate, 'yyyy-MM-dd')}T${formData.endTime}`;
    let startTime = formData.startTime;
    let endTime = formData.endTime;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Sort `recurringDays` by day order
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const sortedRecurringDays = (formData.recurringDays || []).sort(
      (a, b) => daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day)
    );

    // Sort `multipleDays` by date
    const sortedMultipleDays = (formData.multipleDays || []).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    console.log(formData.qrType, sortedMultipleDays.length);

    // Conditionally update start and end dates if qrType is "multipleDays"
    if (formData.qrType === 'randomDates' && sortedMultipleDays.length > 0) {
      startDate = `${sortedMultipleDays[0].date}T00:00`;
      startTime = `${sortedMultipleDays[0].startTime}`;
      endDate = `${sortedMultipleDays[sortedMultipleDays.length - 1].date}T00:00`;
      endTime = `${sortedMultipleDays[sortedMultipleDays.length - 1].endTime}`;
      console.log(startDate, endDate);
    }

    try {
      // API call to register visitor
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/visitor/register`,
        {
          ...formData,
          startTime: startTime,
          endTime: endTime,
          recurringDays: sortedRecurringDays,  // Send sorted recurring days
          multipleDays: sortedMultipleDays,    // Send sorted multiple days
          startDate: startDate,            // Conditionally set start date
          endDate: endDate,                // Conditionally set end date
          timezone: userTimezone,
          PID: formData.PID || tokenPID,
          UID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      // Destructure response data
      const { residentEmail, residentFirstName, firstName, email } = response.data;

      // Set modal content with confirmation message
      setModalContent({
        isOpen: true,
        message: `${residentFirstName}, The system generated a QR Code and it has been emailed to ${firstName}'s email (${email}) and your email address (${residentEmail}) as well.`,
        residentEmail,
        residentFirstName,
        firstName,
        email,
      });

      // Reset form and error states
      setFormData(initialFormData);
      setDateError('');
    } catch (error) {
      console.error('Error during registration:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto py-4 px-0 sm:p-4">
      <form
        onSubmit={handleSubmit}
        className="bg-slate-100 border p-8 rounded-lg shadow-md max-w-lg mx-auto"
        autoComplete="off"
      >
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-center mb-6">Register Visitor</h2>
          <label htmlFor="firstName" className="block text-gray-700 text-sm font-semibold mb-2">First Name</label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter the visitor's first name"
            autoComplete="new-first-name"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="lastName" className="block text-gray-700 text-sm font-semibold mb-2">Last Name</label>
          <input
            id="lastName"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Enter the visitor's last name"
            autoComplete="new-last-name"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter the visitor's email address"
            autoComplete="new-email"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* dropdown with three options */}
        <div className="mb-4">
          <label htmlFor="qrType" className="block text-gray-700 text-sm font-semibold mb-2">Date Option</label>
          <select
            id="qrType"
            name="qrType"
            value={formData.qrType || ''}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          >
            <option value="betweenDates">Between Dates</option>
            <option value="weekdays">Recurring Weekdays</option>
            <option value="randomDates">Specific Dates</option>
          </select>
        </div>

        {/* conditional rendering based on the selected option */}
        {formData.qrType === 'betweenDates' && (
          <BetweenDays formData={formData} handleChange={handleChange} handleChangeDate={handleChangeDate} dateError={dateError} />
        )}
        {formData.qrType === 'weekdays' && (
          <>
            <BetweenDays formData={formData} handleChange={handleChange} handleChangeDate={handleChangeDate} dateError={dateError} />
            <RecurringDays formData={formData} handleChange={handleChange} onValidationChange={handleRecurringDaysValidation} />
          </>
        )}
        {formData.qrType === 'randomDates' && (
          <MultipleDates formData={formData} handleChange={handleChange} onValidationChange={handleMultipleDatesValidation} />
        )}

        {isSuperAdmin && (
          <div className="mb-4">
            <label htmlFor="PID" className="block text-gray-700 text-sm font-semibold mb-2">Property</label>
            <select
              id="PID"
              name="PID"
              value={formData.PID || ''}
              onChange={handleChange}
              placeholder="Select a Property"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            >
              <option value="">Select a Property</option>
              {properties.map(property => (
                <option key={property._id} value={property._id}>
                  {`${property.propertyName} (${property.propertyLocation})`}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Error Message Display */}
        {errorMessage && (
          <div className="p-2 mb-4 bg-red-200 text-red-800 border border-red-400 rounded w-full">
            {errorMessage}
          </div>
        )}

        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={isLoading || dateError || !!errorMessage}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>

      <Modal
        isOpen={modalContent.isOpen}
        onRequestClose={() => setModalContent({ ...modalContent, isOpen: false })}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
          <h2 className="text-lg font-semibold text-green-800">Registration Successful!</h2>
          <p className="mt-2">
            {highlightText(modalContent.message, [
              modalContent.email,
              modalContent.residentEmail
            ])}
          </p>
          <button
            onClick={() => setModalContent({ ...modalContent, isOpen: false })}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterForm;
