import React, { useState } from 'react';
import axios from 'axios';

const RequestPasswordResetPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // Loading state
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);  // Button disable state

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsButtonDisabled(true);  // Disable the button after submit

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/resident/request-password-reset`, { email });
      setSuccess(`An email has been sent to ${email} with instructions to reset your password. This email is valid for 10 minutes.`);
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Error requesting password reset');
      setSuccess('');
    } finally {
      setIsLoading(false);  // Stop loading when response is received
      setIsButtonDisabled(false);  // Re-enable button after response
    }
  };

  return (
    <div className="max-w-lg mx-auto border bg-slate-100 p-6 rounded">
      <h2 className="text-2xl font-bold mb-4">Request Password Reset</h2>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium mb-1">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          disabled={isButtonDisabled} // Disable button while loading
        >
          {isLoading ? 'Sending...' : 'Request Reset Link'}
        </button>
      </form>
    </div>
  );
};

export default RequestPasswordResetPage;
