import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const SetNewPasswordPage = () => {
    const { token } = useParams();  // Extract token from URL params
    const navigate = useNavigate();  // Hook for navigation
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [countdown, setCountdown] = useState(5);  // Countdown for redirection
    const [passwordErrors, setPasswordErrors] = useState({
        minLength: false,
    });
    const [isTokenValid, setIsTokenValid] = useState(true); // State to track if token is valid
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track if form is being submitted

    useEffect(() => {
        const checkTokenValidity = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resident/validate-reset-token/${token}`);
                if (response.status !== 200) {
                    throw new Error('Token expired or invalid');
                }
            } catch (err) {
                setError('The reset token has expired or is invalid');
                setIsTokenValid(false); // Set token validity as false
            }
        };

        checkTokenValidity();
    }, [token]);

    useEffect(() => {
        let timer;
        if (!isTokenValid && countdown > 0) {
            // Countdown every second when token is invalid
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0 && !isTokenValid) {
            // Redirect to the home page once countdown is finished
            navigate('/');
        }
        return () => clearInterval(timer);  // Cleanup interval on unmount
    }, [countdown, isTokenValid, navigate]);

    useEffect(() => {
        let timer;
        if (countdown > 0 && success) {
            // Countdown every second when successful password reset
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0 && success) {
            // Redirect to the home page once countdown is finished after success
            navigate('/');
        }
        return () => clearInterval(timer);  // Cleanup interval on unmount
    }, [countdown, success, navigate]);

    const validatePassword = (password) => {
        setPasswordErrors({
            minLength: password.length >= 6,
        });
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button on submit

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setIsSubmitting(false);
            return;
        }
        if (!passwordErrors.minLength) {
            setError('Password must be at least 6 characters long');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/resident/reset-password/${token}`, { newPassword: password });
            setSuccess(response.data.message);
            setError('');
            setCountdown(5);  // Reset countdown to 5 seconds after successful password reset
        } catch (err) {
            setError(err.response?.data?.message || 'Error resetting password');
            setSuccess('');
        }
        setIsSubmitting(false); // Re-enable the button after the submission process
    };

    return (
        <div className="max-w-lg mx-auto border bg-slate-100 p-6 rounded">
            {error && !isTokenValid && (
                <>
                    <p className="text-red-500">{error}</p>
                    <p className="text-sm text-gray-600">You will be redirected to the home page in {countdown} seconds.</p>
                </>
            )}
            {success && (
                <>
                    <p className="text-green-500">{success}</p>
                    <p className="text-sm text-gray-600">You will be redirected to the login page in {countdown} seconds.</p>
                </>
            )}

            {/* Render form only if token is valid */}
            {isTokenValid && !success && (
                <>
                    <h2 className="text-2xl font-bold mb-4">Set New Password</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="relative">
                            <label htmlFor="password" className="block text-sm font-medium mb-1">New Password:</label>
                            <input
                                id="password"
                                type="password"  // Default type is "password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                                disabled={isSubmitting || success} // Disable input if submitting or success
                                className="w-full border border-gray-300 p-2 rounded"
                            />
                            <div className="mt-2 text-sm text-gray-600">
                                <p className={`text-${passwordErrors.minLength ? 'green' : 'red'}-500`}>
                                    {password.length >= 6 ? '✓ Minimum 6 characters' : '✗ Minimum 6 characters'}
                                </p>
                            </div>
                        </div>

                        <div className="relative">
                            <label htmlFor="confirmPassword" className="block text-sm font-medium mb-1">Confirm New Password:</label>
                            <input
                                id="confirmPassword"
                                type="password"  // Default type is "password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                required
                                disabled={isSubmitting || success} // Disable input if submitting or success
                                className="w-full border border-gray-300 p-2 rounded"
                            />
                        </div>

                        <button 
                            type="submit" 
                            disabled={isSubmitting || success} // Disable button if submitting or success
                            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 mt-4"
                        >
                            {isSubmitting ? 'Submitting...' : 'Set Password'}
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default SetNewPasswordPage;
